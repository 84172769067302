import React, { useEffect } from 'react';
import anime from 'animejs';
import { rem } from 'polished';
import {
  AnimatedLogo,
  Button,
  GlobalStyle,
  ThemeProvider,
  HeroParticles,
  SEO,
} from '../components';
import styled from 'styled-components';
import media from 'styled-media-query';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider>
      <>
        <GlobalStyle />
        <main>{children}</main>
      </>
    </ThemeProvider>
  );
};

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 4rem 4rem 5rem;
  position: relative;
  width: 100vw;
  z-index: 2;
  pointer-events: none;
  flex-flow: column;
`;

const DetailsContainer = styled.div`
  bottom: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: ${rem(14)};
  justify-content: flex-end;
  left: 0;
  padding: 2rem;
  position: absolute;
  text-align: right;
  width: 100%;
  z-index: 9999;
  ${media.lessThan('small')`
    padding: 1rem;
  `};
  a {
    font-size: ${rem(14)};
    text-decoration: none;
  }
  > div {
    margin: 0 1rem;
    ${media.lessThan('small')`
      margin: 0 1rem 0.5rem;
    `};
  }
`;

const SlidesContainer = styled.div`
  position: relative;
  overflow: hidden;
  font-weight: 600;
  margin-top: 2rem;
  max-width: 100%;
  padding: 0 2rem;
  font-size: ${rem(20)};

  ${media.lessThan('medium')`
    font-size: ${rem(18)};
  `};
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  overflow: hidden;
`;

const SlideInner = styled.div`
  opacity: 0;
`;

const LongestText = styled.div`
  position: relative;
  opacity: 0;
`;

const Anchor = styled.a`
  color: inherit;
`;

const texts = [
  'Hello from the early learning community of Sturt Young Learners...',
  'home to kind, extraordinary teachers who..',
  'nurture and inspire amazing happy children...',
  'from 6 weeks to school age.',
  'We’re remarkably different to most early learning centres, and proudly so!',
];

const longestText = texts
  .map(a => a.length)
  .indexOf(Math.max(...texts.map(a => a.length)));

const IndexPage = () => {
  useEffect(() => {
    const timeline = anime.timeline({
      loop: true,
      easing: 'easeOutCirc',
    });

    texts.forEach((element, index) => {
      timeline
        .add(
          {
            targets: `.slide:nth-child(${index + 1}) .inner`,
            translateY: ['150%', '0%'],
            opacity: 1,
            duration: 1200,
          },
          index === 0 ? 0 : '-=1000',
        )
        .add({
          targets: `.slide:nth-child(${index + 1}) .inner`,
          translateY: ['0%', '150%'],
          opacity: 0,
          duration: 2500,
          delay: 5000,
        });
    });
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      <HeroParticles />
      <LogoContainer>
        <AnimatedLogo />
        <SlidesContainer className="slides-container">
          <LongestText>
            {texts.reduce((a, b) => (a.length > b.length ? a : b))}
          </LongestText>
          <div>
            {texts.map(text => (
              <Slide className="slide" key={text}>
                <SlideInner className="inner">{text}</SlideInner>
              </Slide>
            ))}
          </div>
        </SlidesContainer>
      </LogoContainer>
      <DetailsContainer>
        <div>
          <Button
            variant="darkBlue"
            as="a"
            href="mailto:sturtchildcarecentre@ymail.com"
            margin="0.5rem 0"
            width="auto"
            padding="0.6em 2em 0.4em"
          >
            Contact us about Enrolments
          </Button>
        </div>
        <div>Website coming soon!</div>
        <div>
          <Anchor
            href="https://www.google.com/maps/place/151+Sturt+Rd,+Dover+Gardens+SA+5048"
            rel="noopener noreferrer"
          >
            151 Sturt Rd, Dover Gardens, SA 5048
          </Anchor>
        </div>
        <div>
          <Anchor href="tel:0882969329">(08) 8296 9329</Anchor>
        </div>
      </DetailsContainer>
    </Layout>
  );
};

export default IndexPage;
