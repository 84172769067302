import styled from 'styled-components';
import css from '@styled-system/css';
import {
  color,
  position,
  space,
  system,
  typography,
  variant,
  ColorProps,
  PositionProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';
import { rem } from 'polished';

type Props = ColorProps &
  PositionProps &
  SpaceProps &
  TypographyProps & {
    display?: string;
    maxWidth?: string;
    textDecoration?: string;
    transform?: string;
    transformOrigin?: string;
    transition?: string;
    whiteSpace?: string;
    wordSpacing?: string;
  };

const Text = styled.div<Props>`
  ${css({
    color: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'inherit',
  })}

  ${variant({
    variants: {
      h1: {
        fontSize: [rem(32), rem(32), rem(32), rem(48), rem(58), rem(80)],
        margin: '0 0 2rem',
        lineHeight: 'heading',
        fontWeight: 'bold',
        color: 'black',
      },
      h2: {
        fontSize: [rem(24), rem(24), rem(30), rem(32), rem(44), rem(60)],
        margin: '0 0 2rem',
        lineHeight: 'heading',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: [rem(20), rem(20), rem(20), rem(22), rem(25), rem(40)],
        margin: '0 0 2rem',
        lineHeight: 'heading',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: [rem(18), rem(18), rem(20), rem(24), rem(26), rem(30)],
        margin: '0 0 2rem',
        lineHeight: 1.5,
        fontWeight: 'semibold',
      },
      h5: {
        fontSize: [rem(17), rem(17), rem(18), rem(20), rem(22), rem(26)],
        margin: '0 0 2rem',
        lineHeight: 'heading',
        fontWeight: 'semibold',
      },
      h6: {
        fontSize: [rem(16), rem(16), rem(16), rem(18), rem(18)],
        margin: '0 0 2rem',
        lineHeight: 'heading',
        fontWeight: 'semibold',
      },
      p: {
        fontSize: [rem(14), rem(14), rem(16), rem(18), rem(20), rem(20)],
        margin: '0 0 1rem',
        fontWeight: 'body',
        lineHeight: 'body',
        fontWeight: 'medium',
      },
    },
  })};

  &,
  && {
    ${color};
    ${position};
    ${space};
    ${typography};
    ${system({
      display: true,
      maxWidth: true,
      textDecoration: true,
      transform: true,
      transformOrigin: true,
      transition: true,
      whiteSpace: true,
      wordSpacing: true,
    })}
  }
`;

export { Text };

export default Text;
