import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import Box from './Box';
import Grid from './Grid';
import Image from './Image';
import Button from './Button';
import Text from './Text';
import Link from './Link';

const navigation = themeContext => [
  {
    title: 'About us',
    to: '/about-us',
    activeColor: themeContext.colors.lavander,
    subnavigation: [
      {
        title: 'Our Philosophy',
        to: '/about-us#our-philosophy',
      },
      {
        title: 'Our Team',
        to: '/about-us#our-team',
      },
      {
        title: 'Our Centre',
        to: '/about-us#our-centre',
      },
    ],
  },
  {
    title: 'Curriculum',
    to: '/curriculum',
    activeColor: themeContext.colors.blue,
    subnavigation: [
      {
        title: 'Early Learning Years',
        to: '/curriculum#early-learning-years',
      },
      {
        title: 'Teaching Strategies',
        to: '/curriculum#teaching-strategies',
      },
      {
        title: 'Curriculum',
        to: '/curriculum#curriculum',
      },
      {
        title: 'Universal Access Preschool',
        to: '/curriculum#universal-access-preschool',
      },
    ],
  },
  {
    title: 'Parent Information',
    to: '/parent-information',
    activeColor: themeContext.colors.orange,
    subnavigation: [
      {
        title: 'Opening Times',
        to: '/parent-information#opening-times',
      },
      {
        title: 'Nutrition',
        to: '/parent-information#nutrition',
      },
      {
        title: 'Bookings and Fees',
        to: '/parent-information#bookings-and-fees',
      },
    ],
  },
  {
    title: 'Gallery',
    activeColor: themeContext.colors.green,
    to: '/gallery',
    hideInFooter: true,
  },
  {
    title: 'Contact',
    to: '/contact',
    activeColor: themeContext.colors.pink,
    subnavigation: [
      {
        title: 'Contact',
        to: '/contact#contact',
      },
      {
        title: 'Careers',
        to: '/contact#careers',
      },
    ],
  },
];

const NavigationLink = styled(Link)`
  &.active {
    color: ${props => props.activeColor};
  }
`;

const Header = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  const themeContext = useContext(ThemeContext);

  const themedNavigation = navigation(themeContext);

  return (
    <>
      <Box marginY={{ default: '2rem', large: '4rem' }}>
        <Grid.Row>
          <Grid.Column>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Link to="/">
                  <Image
                    src={require('../images/logo.svg')}
                    width={{
                      default: rem(100),
                      medium: rem(200),
                      large: rem(300),
                    }}
                  />
                </Link>
              </Box>
              <Box
                display={{ default: 'flex', medium: 'none' }}
                alignItems="flex-end"
                flexFlow="column"
              >
                <Box
                  onClick={() => {
                    setNavigationOpen(!navigationOpen);
                  }}
                >
                  Burger
                </Box>
              </Box>
              <Box
                display={{ default: 'none', medium: 'flex' }}
                alignItems="flex-end"
                flexFlow="column"
              >
                <Box display="flex" marginBottom="1.5rem">
                  <Box>
                    <Button
                      styleVariant="hollow"
                      variant="teal"
                      fontSizeVariant="small"
                    >
                      8296 9329
                    </Button>
                  </Box>
                  <Box marginLeft="2rem">
                    <Button fontSizeVariant="small">Enquire</Button>
                  </Box>
                </Box>
                <Box display="flex">
                  {themedNavigation.map(({ title, to, activeColor }) => (
                    <NavigationLink
                      key={title}
                      marginLeft="2rem"
                      fontWeight="bold"
                      fontSize={rem(20)}
                      to={to}
                      textDecoration="none"
                      activeClassName="active"
                      activeColor={activeColor}
                    >
                      {title}
                    </NavigationLink>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Box>
      {navigationOpen && (
        <>
          <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="9998"
            onClick={() => {
              setNavigationOpen(false);
            }}
          />
          <Box
            position="fixed"
            top="0"
            right="0"
            height="100%"
            backgroundColor="black"
            padding="2rem"
            color="white"
            zIndex="9999"
          >
            <Box>
              <Box>
                <Box
                  onClick={() => {
                    setNavigationOpen(false);
                  }}
                >
                  X
                </Box>
              </Box>
              {themedNavigation.map(
                ({ title, to, subnavigation, hideInFooter }) =>
                  !hideInFooter && (
                    <Box key={title} marginBottom="1rem">
                      <Link
                        to={to}
                        fade={true}
                        textDecoration="none"
                        fontSize={{ default: rem(16), large: rem(30) }}
                        fontWeight="bold"
                        marginBottom="0.5rem"
                        display="inline-block"
                      >
                        {title}
                      </Link>
                      {subnavigation && (
                        <Box>
                          {subnavigation.map(
                            ({
                              title: subnavigationTitle,
                              to: subnavigationLink,
                            }) => (
                              <Box key={subnavigationTitle}>
                                <Link
                                  to={subnavigationLink}
                                  textDecoration="none"
                                  fontSize={{
                                    default: rem(16),
                                    large: rem(18),
                                  }}
                                  fontWeight="semibold"
                                  color="lavander"
                                  marginBottom="0.5rem"
                                  display="inline-block"
                                >
                                  {subnavigationTitle}
                                </Link>
                              </Box>
                            ),
                          )}
                        </Box>
                      )}
                    </Box>
                  ),
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export { Header, navigation };

export default Header;
