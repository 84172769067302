import styled from 'styled-components';
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  OverflowProps,
  PositionProps,
  SpaceProps,
  TypographyProps,
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography,
} from 'styled-system';

type Props = BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  OverflowProps &
  TypographyProps &
  SpaceProps & {
    transform?: string;
    transition?: string;
    pointerEvents?: string;
    flexFlow?: string;
    cursor?: string;
    zIndex?: string;
    borderRadius?: string;
  };

const Box = styled.div<Props>`
  &,
  && {
    ${background};
    ${border};
    ${color};
    ${flexbox};
    ${grid};
    ${layout};
    ${position};
    ${space};
    ${typography};
    ${system({
      transform: true,
      transition: true,
      pointerEvents: true,
      flexFlow: true,
      cursor: true,
      zIndex: true,
    })}
  }
`;

export { Box, Props };

export default Box;
