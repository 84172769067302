import { rem } from 'polished';

type FontWeight =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black'
  | 'body';

const fontWeights = {
  thin: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const breakpoints = [
  rem(375),
  rem(450),
  rem(768),
  rem(1170),
  rem(1440),
  rem(1960),
];

const space = ['0px', '2rem', '6rem', '8rem'];

const theme = {
  fontSizes: [rem(12), rem(14), rem(16), rem(20), rem(24), rem(32)],
  colors: {
    black: '#212c65',
    body: '#212c65',
    bodyBackground: '#fefefe',
    darkGray: '#212327',
    error: '#ff3333',
    lightGray: '#f0f0fa',
    mediumGray: '#575a5f',
    primary: '#e81f76',
    secondary: '#212c65',
    white: '#ffffff',
    // other colors
    blue: '#00c1de',
    darkBlue: '#212c65',
    lavander: '#8093DC',
    green: '#009845',
    lightPurple: '#d8c4e5',
    lightBlue: '#95d4e9',
    magenta: '#e81f76',
    orange: '#f88d2b',
    peach: '#ff647d',
    pink: '#eeb2ca',
    purple: '#a05cbf',
    red: '#eb0045',
    teal: '#6cca98',
    yellow: '#ffcd00',
  },
  fonts: {
    heading: `'gilroy', 'sans-serif'`,
    body: `'gilroy', 'sans-serif'`,
  },
  fontWeights: {
    ...fontWeights,
    body: fontWeights.medium,
  },
  breakpoints,
  radii: rem(8),
  lineHeights: {
    heading: 1.2,
    body: 1.5,
  },
  space,
  // can't use arrays in aready styled system defined things
  spacings: {
    small: ['1rem', '1rem', '1rem', '2rem', '4rem', '4rem'],
    medium: ['2rem', '2rem', '2rem', '4rem', '6rem', '6rem'],
    large: ['3rem', '3rem', '3rem', '6rem', '8rem', '8rem'],
    xlarge: ['3rem', '3rem', '3rem', '6rem', '10rem', '10rem'],
    huge: ['4rem', '4rem', '4rem', '6rem', '10rem', '20rem'],
  },
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
};

// @ts-ignore
theme.space.small = space[1];
// @ts-ignore
theme.space.medium = space[2];
// @ts-ignore
theme.space.large = space[3];

// @ts-ignore
theme.breakpoints.xsmall = breakpoints[0];
// @ts-ignore
theme.breakpoints.small = breakpoints[1];
// @ts-ignore
theme.breakpoints.medium = breakpoints[2];
// @ts-ignore
theme.breakpoints.large = breakpoints[3];
// @ts-ignore
theme.breakpoints.xlarge = breakpoints[4];
// @ts-ignore
theme.breakpoints.huge = breakpoints[5];

type Theme = typeof theme;

export { FontWeight, Theme, breakpoints, fontWeights, space, theme };

export default theme;
