import React from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import { rem } from 'polished';
import Box, { Props as BoxProps } from './Box';
import Link from './Link';
import { variant, VariantArgs } from 'styled-system';

export const fontSizeVariant = variant({
  prop: 'fontSizeVariant',
  variants: {
    small: {
      fontSize: [rem(16), rem(16), rem(16), rem(16), rem(20)],
      padding: [
        '0.5rem 0.5rem',
        '0.5rem 0.5rem',
        '0.5rem 0.5rem',
        '0.5rem 0.5rem',
        '0.5rem 2rem',
      ],
      width: rem(195),
    },
    large: {
      fontSize: [rem(16), rem(18), rem(18), rem(24), rem(26)],
      width: rem(300),
      padding: [
        '0.75em 0.5em',
        '0.75em 0.5em',
        '0.75em 0.5em',
        '1em 2em',
        '1em 2em',
      ],
    },
  },
} as VariantArgs);

const styleVariant = (props: any) =>
  variant({
    prop: 'styleVariant',
    variants: {
      default: {
        backgroundColor: `${props.theme.colors[props.variant]}`,
        borderColor: `${props.theme.colors[props.variant]}`,
      },
      hollow: {
        borderColor: `${props.theme.colors[props.variant]}`,
        color: `${props.theme.colors[props.variant]}`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: `${props.theme.colors[props.variant]}`,
        },
      },
    },
  } as VariantArgs);

const Button = styled(Box)<
  BoxProps & {
    fontSizeVariant?: string;
    variant?: string;
    styleVariant?: string;
  }
>`
  ${css({
    maxWidth: '100%',
    borderRadius: rem(500),
    color: 'white',
    display: 'inline-block',
    textAlign: 'center',
    as: 'button',
    fontFamily: 'body',
    fontWeight: 'bold',
    borderWidth: '2px',
    borderStyle: 'solid',
    cursor: 'pointer',
    backgroundColor: 'primary',
  })}

  ${fontSizeVariant};
  ${styleVariant};
`;

Button.defaultProps = {
  variant: 'primary',
  fontSizeVariant: 'large',
  styleVariant: 'default',
};

export { Button };

export default Button;
