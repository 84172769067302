import React from 'react';
import styled from 'styled-components';
import {
  border,
  layout,
  position,
  space,
  system,
  BorderProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system';

type Props = BorderProps &
  LayoutProps &
  PositionProps &
  SpaceProps & {
    transition?: string;
    transform?: string;
    pointerEvents?: string;
  };

const ImageComponent = styled.img.attrs({ draggable: false })<Props>`
  ${border};
  ${layout};
  ${position};
  ${space}
  ${system({
    transform: true,
    transition: true,
    pointerEvents: true,
  })}
`;

ImageComponent.defaultProps = {
  width: '100%',
  height: 'auto',
  maxWidth: '100%',
};

const Image = (props: Props) => (
  <ImageComponent
    {...props}
    alt={
      props.alt ? `${props.alt} | Sturt Young Learners` : 'Sturt Young Learners'
    }
  />
);

export { Image };

export default Image;
