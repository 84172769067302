import React, { useEffect } from 'react';
import anime from 'animejs';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  max-width: 100%;
`;

const AnimatedLogo = () => {
  useEffect(() => {
    const logoTimeline = anime
      .timeline({
        endDelay: 3000,
      })
      .add({
        targets: '.fill-in path',
        fill: {
          value: (el: any, i: any) => {
            return ['rgba(255,255,255,0)', el.getAttribute('data-fill')];
          },
          duration: 500,
          delay: (el: any, i: any, t: any) => {
            return 200 + i * 100;
          },
          easing: 'linear',
        },
        offset: 0,
      });
  });

  return (
    <div>
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        width="288.2"
        height="120.69"
        viewBox="0 0 288.2 120.69"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1" className="fill-in">
            <path
              className="logo-top logo-char-01-pink"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#eeb2ca"
              d="M19.37,21.49c0-2.88,2.56-5.45,7.7-5.46,6.57,0,10,3.17,12.44,8.3L53.37,16.2C48.12,5.62,38.86,0,27,0,14.61,0,2.91,8.05,2.93,21.92c0,9.36,4.86,14.72,11.32,18.12L30.61,29.87C21,26.79,19.37,24.6,19.37,21.49Z"
            />
            <path
              className="logo-top logo-char-01-purple"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#a05cbf"
              d="M55.48,52.67c0-15.21-12.26-19-23.15-22.26l-1.72-.54L14.25,40a49.59,49.59,0,0,0,9.88,3.73C34.92,46.74,39,48.48,39,52.9a4.78,4.78,0,0,1-1,3L50.84,66.14A20.35,20.35,0,0,0,55.48,52.67Z"
            />
            <path
              className="logo-top logo-char-01-blue"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#95d4e9"
              d="M29.29,58.77c-8.12,0-12.55-4-15.12-10L0,57.06C4,67.64,13.69,74.82,28.79,74.8c9.55,0,17.39-3.1,22.05-8.66L38.07,55.88C36.84,57.51,34.25,58.76,29.29,58.77Z"
            />
            <path
              className="logo-top logo-char-02-green"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#6cca98"
              d="M109.44 1.32 55.98 1.4 56 17.22 74.5 17.2 90.94 17.17 109.46 17.15 109.44 1.32Z"
            />
            <path
              className="logo-top logo-char-02-pink"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#e81f76"
              d="M74.54 17.18 90.94 17.18 90.94 73.28 74.54 73.28"
            />
            <path
              className="logo-top logo-char-03-red"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#eb0045"
              d="M131.03 1.29 114.59 1.32 114.66 49.26 131.08 41.02 131.03 1.29Z"
            />
            <path
              className="logo-top logo-char-03-blue"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#00c1de"
              d="M153.69,41.94v6.58c0,5.45-2.56,10.08-11.29,10.09s-11.31-4.61-11.32-10V41l-16.43,8.24V50a26.74,26.74,0,0,0,.82,6.65s0,0,0,0c3,11.35,13.56,17.94,26.93,17.92,13,0,23.43-6.33,26.65-17.19a.56.56,0,0,0,0-.12,26,26,0,0,0,1-7.39v0Z"
            />
            <path
              className="logo-top logo-char-03-green"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#009845"
              d="M170.07 1.24 153.63 1.26 153.69 41.94 170.14 49.92 170.07 1.24Z"
            />
            <path
              className="logo-top logo-char-04-yellow"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#d8c4e5"
              d="M194.94 35.11 194.92 16.61 194.68 1.2 178.46 1.23 178.56 73.15 195 73.13 194.97 49.49Z"
            />
            <path
              className="logo-top logo-char-04-orange"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#f88d2b"
              d="M207.25,16.6c4.36,0,7.95,3.72,8.21,8.68l16.45,0A24.61,24.61,0,0,0,207.23,1.18l-12.55,0,.24,15.41Z"
            />
            <path
              className="logo-top logo-char-04-yellow"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#ffcd00"
              d="M195,49.49h7.8l13.6,23.61,17.67,0L218.7,46.68a23.69,23.69,0,0,0,13.22-20.87c0-.19,0-.37,0-.55l-16.45,0c0,.18,0,.36,0,.55,0,5.24-3.68,9.25-8.21,9.26l-12.33,0"
            />
            <path
              className="logo-top logo-char-05-pink"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#ff647d"
              d="M253.27 16.94 234.77 16.97 234.75 1.15 288.18 1.07 288.2 16.89 269.71 16.92Z"
            />
            <path
              className="logo-top logo-char-05-blue"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#8093dc"
              d="M269.71 16.92 269.79 73.02 253.35 73.04 253.27 16.94Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M26.63,91.75,19.25,112c-2.11,5.83-5.56,8.39-10.7,8.1v-4.86c2.88,0,4.33-1.18,5.31-3.89L5.55,91.75h5.71l5.27,13.62L21,91.75Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M27.19,101.88a10.72,10.72,0,1,1,10.7,10.7A10.59,10.59,0,0,1,27.19,101.88Zm16.22,0a5.5,5.5,0,1,0-5.52,5.6A5.39,5.39,0,0,0,43.41,101.88Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M70.89,91.75V112H65.66v-2.27a6.91,6.91,0,0,1-6,2.83c-4.3,0-7.67-3-7.67-8.39V91.75h5.23v11.79c0,2.76,1.67,4.18,4,4.18,2.55,0,4.46-1.5,4.46-5V91.75Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M94.55,99.57V112H89.32v-11.8c0-2.76-1.66-4.18-4-4.18-2.55,0-4.46,1.5-4.46,5v11H75.62V91.75h5.23V94a6.89,6.89,0,0,1,6-2.84C91.19,91.18,94.55,94.18,94.55,99.57Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M119.6,91.75V111c0,6.65-5.23,9.65-10.58,9.65-4.34,0-7.82-1.66-9.6-5l4.45-2.55c.85,1.58,2.19,2.84,5.31,2.84,3.29,0,5.31-1.78,5.31-5v-2.19a7.71,7.71,0,0,1-6.48,3.08,10.38,10.38,0,0,1,0-20.75,7.73,7.73,0,0,1,6.48,3.08V91.75Zm-5.11,9.81a5.68,5.68,0,1,0-5.67,5.55A5.42,5.42,0,0,0,114.49,101.56Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M136.05,84h5.23v28h-5.23Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M156.2,107.84a5.61,5.61,0,0,0,4.54-2l4.21,2.44a10.25,10.25,0,0,1-8.83,4.33c-6.81,0-11.11-4.66-11.11-10.7a10.35,10.35,0,0,1,10.7-10.7c6,0,10.26,4.74,10.26,10.7a11.36,11.36,0,0,1-.21,2.15H150.48C151.21,106.71,153.44,107.84,156.2,107.84Zm4.54-7.86a4.93,4.93,0,0,0-5-4.1,5.05,5.05,0,0,0-5.31,4.1Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M190.08,91.75V112h-5.23v-2.4a8.05,8.05,0,0,1-6.6,3c-5.4,0-9.85-4.66-9.85-10.7s4.45-10.7,9.85-10.7a8.08,8.08,0,0,1,6.6,3V91.75Zm-5.23,10.13a5.62,5.62,0,1,0-5.63,5.72A5.45,5.45,0,0,0,184.85,101.88Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M206.21,91.34v5.84c-2.72-.32-6.16.89-6.16,5.15V112h-5.23V91.75h5.23v3.48A6.24,6.24,0,0,1,206.21,91.34Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M228.18,99.57V112H223v-11.8c0-2.76-1.66-4.18-4-4.18-2.56,0-4.46,1.5-4.46,5v11h-5.23V91.75h5.23V94a6.87,6.87,0,0,1,6-2.84C224.81,91.18,228.18,94.18,228.18,99.57Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M242.77,107.84a5.63,5.63,0,0,0,4.54-2l4.21,2.44a10.25,10.25,0,0,1-8.83,4.33c-6.81,0-11.11-4.66-11.11-10.7a10.35,10.35,0,0,1,10.7-10.7c6,0,10.26,4.74,10.26,10.7a12.25,12.25,0,0,1-.21,2.15H237.05C237.78,106.71,240,107.84,242.77,107.84Zm4.54-7.86a4.93,4.93,0,0,0-5-4.1A5.05,5.05,0,0,0,237,100Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M267.57,91.34v5.84c-2.71-.32-6.16.89-6.16,5.15V112h-5.23V91.75h5.23v3.48A6.24,6.24,0,0,1,267.57,91.34Z"
            />
            <path
              className="logo-bottom"
              fill="rgba(255, 255, 255, 0)"
              data-fill="#212c65"
              d="M285.24,106.1c0,4.38-3.81,6.48-8.14,6.48-4.06,0-7.06-1.7-8.52-4.82l4.54-2.55a3.87,3.87,0,0,0,4,2.63c1.66,0,2.79-.57,2.79-1.74,0-3-10.46-1.34-10.46-8.47,0-4.14,3.53-6.45,7.71-6.45a8.53,8.53,0,0,1,7.7,4.3l-4.46,2.43a3.42,3.42,0,0,0-3.24-2.07c-1.3,0-2.36.57-2.36,1.66C274.78,100.5,285.24,98.64,285.24,106.1Z"
            />
          </g>
        </g>
      </StyledSvg>
    </div>
  );
};

export default AnimatedLogo;
