import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import { rem, stripUnit } from 'polished';
import css from '@styled-system/css';
import { theme } from '../styles';

type Props = React.ComponentProps<typeof Box>;

const scaleRem = (value, scale) => {
  const [dimension, unit] = stripUnit(value, true);

  return `${dimension * scale}${unit}`;
};

const defaultGutter = ['1.25rem', '2rem', '3rem', '4rem', '4rem'];

const defaultColumnSpacing = ['1rem', '2rem', '2rem', '2rem', '3rem'];

const defaultRowWidth = rem(1528);

const ColumnComponent: React.SFC<Props> = ({ children, ...rest }) => (
  <Box
    display="flex"
    flex="0 0 auto"
    flexDirection="column"
    maxWidth="100%"
    width="100%"
    {...rest}
  >
    {children}
  </Box>
);

const Column = styled(ColumnComponent)``;

const RowComponent: React.SFC<Props> = ({ children, gutter, ...rest }) => (
  <Box
    margin="0 auto"
    maxWidth={defaultRowWidth}
    paddingX={defaultGutter}
    width="100%"
    {...rest}
  >
    <Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {children}
      </Box>
    </Box>
  </Box>
);

const Row = styled(RowComponent)<{ columnSpacing?: { [key]: string } }>`
  ${Column} {
    ${css({
      paddingX: ({ columnSpacing }) =>
        (columnSpacing || defaultColumnSpacing).map(value =>
          scaleRem(value, 0.5),
        ),
    })}
  }

  ${Column} & {
    ${css({
      paddingX: 0,
      '> div': {
        marginX: ({ columnSpacing }) =>
          (columnSpacing || defaultColumnSpacing).map(value =>
            scaleRem(value, -0.5),
          ),
      },
    })};
  }
`;

Row.displayName = 'Grid.Row';

Column.displayName = 'Grid.Column';

const Grid: { Column: typeof Column; Row: typeof Row } = {
  Column,
  Row,
};

export { Grid, defaultGutter, defaultColumnSpacing, defaultRowWidth };

export default Grid;
