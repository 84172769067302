import { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 90%;
    ${({ theme }) => `
      @media screen and (min-width: ${theme.breakpoints.xsmall}) {
        font-size: 100%;
      }
      @media screen and (min-width: ${theme.breakpoints.xlarge}) {
        font-size: 90%;
      }
      @media screen and (min-width: ${theme.breakpoints.huge}) {
        font-size: 110%;
      }
    `}
  }
  body {
    font-family: ${({ theme }) => theme.fonts.body};
    background: ${({ theme }) => theme.colors.bodyBackground};
    color: ${({ theme }) => theme.colors.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  * {
    box-sizing: border-box;
  }
`;

export { GlobalStyle };

export default GlobalStyle;
