import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { theme } from '../theme';

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      {children}
    </StyledComponentsThemeProvider>
  );
};

export { ThemeProvider };

export default ThemeProvider;
